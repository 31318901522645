import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['container'];
  static values = { url: String };

  connect() {
    this.loaded = false;
  }

  load() {
    if (this.loaded) return;

    fetch(this.urlValue)
      .then(response => response.json())
      .then(json => {
        this.containerTarget.style.overflow = 'hidden';
        this.containerTarget.innerHTML = json.html;
        this.containerTarget.style.overflow = 'auto';
        this.loaded = true;
      });
  }
}

